/* Container for the dashboard */
.dashboard-container {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.dashboard-container h1 {
    font-size: 2.5rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 1rem #555555;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 
                1px 1px 0 #e91313, 
                2px 2px 0 #e91313, 
                
}

/* Error Message Styling */
.error-message {
    color: red;
    font-size: 1rem;
    text-align: center;
}

/* List of bookings */
.bookings-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center; /* Aligns the cards to the center */
    width: 100%;
    margin: 0 auto;
}

/* Individual Booking Card Styling */
.booking-card {
    flex: 1 1 calc(33.33% - 1rem); /* Three cards per row */
    max-width: calc(33.33% - 1rem);
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    
    transition: transform 0.2s ease-in-out;
}

.booking-card:hover {
    transform: translateY(-5px); /* Subtle lift effect on hover */
}

.booking-card h3 {
    font-size: 2rem;
    color: #333333;
    margin-top: 0;
}

.booking-card p {
    font-size: 1rem;
    color: #555555;
    margin: 5px 0;
}

.booking-card p strong {
    color: #333333;
}

/* Responsive Design */
@media (max-width: 900px) {
    .booking-card {
        flex: 1 1 calc(50% - 1rem); /* Two cards per row */
        max-width: calc(50% - 1rem);
    }
}

@media (max-width: 600px) {
    .dashboard-container {
        padding: 10px;
    }

    .bookings-list {
        gap: 0.5rem;
    }

    .booking-card {
        flex: 1 1 calc(100% - 1rem); /* One card per row */
        max-width: calc(100% - 1rem);
        padding: 10px;
    }

    .booking-card h3 {
        font-size: 18px;
    }

    .booking-card p {
        font-size: 14px;
    }
}
