/* Footer */
footer {
    background: #333;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
}

footer .footer-links {
    margin-bottom: 1rem;
}

footer .footer-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 0.5rem;
}

footer .social-media {
    margin-top: 1rem; /* Add margin top for spacing */
}

footer .social-media a {
    color: #fff;
    text-decoration: none;
    margin: 0 0.5rem;
    font-size: 1.2rem;
}

footer .social-media a:hover {
    color: #f4b400;
}

/* Responsive Styles */
@media (max-width: 600px) {
    footer .footer-links, 
    footer .social-media {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    footer .footer-links a,
    footer .social-media a {
        margin: 0.5rem 0;
    }
}
