/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
}

/* Hero Section */
.hero {
    background: url('../assets/images/logo.jpg') no-repeat center center/cover;
    color: #170f0f;
    text-align: center;
    
    padding: 5rem 1rem;
    position: relative;
}

.hero h1 {
    font-family: 'Tiro Devanagari Sanskrit', serif; /* Headings in a traditional font */
    color: hsl(48, 100%, 20%); /* Dark, earthy yellow tone for better contrast against orange */
    font-weight: 400;
    word-break: break-all;
    font-size: 1.5rem; /* Base font size for small screens */
    
     /* Responsive typography */
    @media (min-width: 480px) {
        font-size: 1rem; /* Slightly larger for small phones */
    }

    @media (min-width: 768px) {
        font-size: 1.5rem; /* Increase size on tablets */
    }

    @media (min-width: 1024px) {
        font-size: 2rem; /* Increase size on desktops */
    }

    @media (min-width: 1440px) {
        font-size: 2.5rem; /* Increase size for larger screens */
    }
}


.hero p {
    font-size: 1rem;
    color: hsl(48, 100%, 20%); /* Dark, earthy yellow tone for better contrast against orange */
}

/* Search Bar */
.search-bar {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.search-bar select,
.search-bar button {
    padding: 0.5rem;
    font-size: 1rem;
    margin: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.search-bar button {
    background: #f4b400;
    border: none;
    color: #fff;
    cursor: pointer;
}

.search-bar button:hover {
    background: #e0a800;
}



/* Glowing Text Animation */
@keyframes glow {
    0%, 100% {
        text-shadow: 0 0 5px #f4b400, 0 0 10px #f4b400, 0 0 15px #f4b400, 0 0 20px #f4b400, 0 0 25px #f4b400, 0 0 30px #f4b400, 0 0 35px #f4b400;
    }
    50% {
        text-shadow: 0 0 10px #f4b400, 0 0 20px #f4b400, 0 0 30px #f4b400, 0 0 40px #f4b400, 0 0 50px #f4b400, 0 0 60px #f4b400, 0 0 70px #f4b400;
    }
}

.glowing-text {
    animation: glow 6.5s infinite alternate;
}

/* Popup Form Styling */
.popup {
    display: inside; /* Initially hidden */
    position: relative;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.popup-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #707070;
    width: 80%;
    max-width: 500px;
    position: relative;
    border-radius: 5px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

form label {
    display: block;
    margin: 15px 0 5px;
}

form input[type="text"],
form input[type="number"],
form input[type="tel"],
form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin: 5px 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

form button {
    background-color: #f4b400;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

form button:hover {
    background-color: #d49e00;
}

/* Available Panditjis Section */
.available-panditji {
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    padding: 2rem 0;
    text-align: center;
}

.panditjicontainer h2{
    font-family: 'Tiro Devanagari Sanskrit', serif; /* Headings in a traditional font */
    color: #b53f3f; /* Rich earthy tone */
    font-size: 2rem;
}

.panditji-section {
    flex: 1 1 calc(33.333% - 20px);
    margin: 10px;
    padding: 5px;
    border: 1px solid #080808;
    border-radius: 5px;
    box-sizing: border-box;
}

.panditji-section h3 {
    font-size: 2rem;
    margin: 5px;
}

.panditji-section:hover {
    transform: translateY(-10px);
}

.panditji-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
}

.panditji-box {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    width: 20%;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(235, 6, 6, 0.1);
    transition: transform 0.3s;
    margin: 1rem 0.5rem;
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.panditji-box:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.panditji-box img {
    width: 100%;           /* Make the image fill the container's width */
    height: auto;          /* Maintain aspect ratio */
    object-fit: contain;   /* Ensure the image is fully visible and not cropped */
    border-radius: 10px;   /* Add some rounding if needed */
    aspect-ratio: 3/2;     /* Adjust the aspect ratio of the container if needed */
     /* Optional: add a background color to handle empty space */
}

.panditji-box h3 {
    font-family: 'Tiro Devanagari Sanskrit', serif; /* Headings in a traditional font */
    color: #b53f3f; /* Rich earthy tone */
    font-size: x-large;
    margin: 0; /* Remove default margin */
    text-align: center; /* Center the text */
    margin-bottom: 0.5rem; /* Space between heading and paragraph */
}

.panditji-box p {
    font-size: 1rem;
    text-align: center;
    margin: 0; /* Remove default margin */
}

.panditji-box .options {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s, visibility 0.1s;
}

.panditji-box:hover .options {
    opacity: 1;
    visibility: visible;
}

.panditji-box .options button {
    height: 30px; /* Sets a specific height */
    padding: 0 10px; /* Adjusts padding to ensure content fits within height */
    border: none;
    background: #89f1f0;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s;
    line-height: 30px; /* Vertically centers text */
}

.panditji-box .options button:hover {
    background: #567;
}

/* Responsive Design */
@media (max-width: 768px) {
    .search-bar {
        flex-direction: column;
        align-items: center;
    }

    .search-bar select,
    .search-bar button {
        width: 80%;
        margin: 0.5rem 0;
    }

    .steps {
        flex-direction: column;
        align-items: center;
    }

    .step {
        margin: 1rem 0;
    }

    .panditji-section, .panditji-box {
        flex: 1 1 calc(100% - 20px);
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .panditji-section, .panditji-box {
        flex: 1 1 calc(50% - 20px);
    }
}
.booking-container {
    width: 100%;
    padding: 20px;
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.bookings {
    max-width: 100%; /* Make sure the container uses full width */
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.error-message {
    color: #d9534f;
    text-align: center;
    margin-bottom: 20px;
}

.bookings-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.booking-card {
    border-radius: 10px;
    padding: 20px;
    margin: 0 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    flex: 0 0 55%; /* Set card width to 35% of the container */
    width: 55%; /* Ensure card does not exceed 35% width */
    word-wrap: break-word; /* Ensure long words break and wrap */
}

.booking-card h3 {
    font-size: 1rem !important;
    margin-bottom: 10px;
    /* word-wrap:break-word; */
}

.booking-card p {
    margin: 5px 0;
    color: #666;
    word-wrap:break-word;
}

.booking-card p strong {
    color: #333;
}

/* Slider controls */
.slick-prev, .slick-next {
    color: #333;
    font-size: 24px;
    line-height: 1;
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .booking-card {
        flex: 0 0 55%; /* Show fewer cards per row */
        width: 55%; /* Ensure consistency with flex */
    }
}

@media (max-width: 768px) {
    .booking-card {
        flex: 0 0 50%; /* Show 2 cards per row */
        width: 50%; /* Ensure consistency with flex */
    }
}

@media (max-width: 480px) {
    .booking-card {
        flex: 0 0 100%; /* Show 1 card per row */
        width: 100%; /* Ensure consistency with flex */
    }
}

.offer-banner {
  display: inline-block;
  padding: 15px 30px;
  margin-top: 20px;
  background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
  color: #333; /* Text color */
  border-radius: 50px; /* Round button */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* 3D effect */
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.offer-banner:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8); /* Enhance shadow on hover */
}

.offer-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.1));
  z-index: -1; /* Behind the button */
  filter: blur(15px); /* Glow effect */
}

.offer-banner:hover::before {
  background: linear-gradient(45deg, rgba(255, 255, 0, 0.5), rgba(255, 0, 0, 0.1));
}
