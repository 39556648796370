/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.pooja-services-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.pooja-services-container h1 {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  color: #e64a19;
  margin-bottom: 2rem;
}

.intro-text {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #555;
}

.service-section {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.service-icon {
  font-size: 2.5rem;
  color: #e64a19;
  margin-right: 1rem;
}

.service-text h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.service-text p, 
.service-text ul li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  color: #555;
}

.service-text ul {
  padding-left: 1.2rem;
}

.final-text {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 3rem;
  color: #555;
}

/* Hover effect */
.service-section:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

/* Responsive */
@media screen and (max-width: 768px) {
  .service-section {
    flex-direction: column;
    text-align: center;
  }

  .service-icon {
    margin-bottom: 1rem;
  }
}
