/* AboutUs.css */
.aboutcontainer {
    max-width: 100%;
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
}

h1 {
    
    font-size: 2.5rem;
    color: #333;
}

h2 {
    
    font-size: 2rem;
    color: #555;
}

h4 {
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);

    font-size: 1.5rem;
    color: #333;
}

p {
    font-size: 1rem;
    color: #666;
}

.team-member {
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.team-member img {
    width:250px;
    height: 350px;
    object-fit: cover;
    border: 4px solid #007bff;
    border-radius: 50%;
}

ul {
    /* background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff); */
    list-style-type:none;
    padding-left: 20px;
}

ul li {
    
    font-size: 1rem;
    color: #555;
}
