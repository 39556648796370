/* Add your common styles here */
body {
    /* background: linear-gradient(to bottom, #87CEEB, #ADD8E6, #F5F5F5);; */
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden
}

header, footer {
    background: #333;
    color: #fff;
    padding: 10px 0;
}

header .container, footer .container {
    width: 80%;
    margin: 0 auto;
}

header .logo {
    font-size: 24px;
}

header nav ul {
    list-style: none;
    padding: 0;
}

header nav ul li {
    display: inline;
    margin-right: 15px;
}

header nav ul li a, header nav ul li .nav-link {
    color: #fff;
    text-decoration: none;
}

.nav-dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-menu li {
    display: block;
}

.dropdown-menu li a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.hero {
    background: #f4f4f4;
    padding: 60px 0;
    text-align: center;
}

.hero h1 {
    font-size: 36px;
}

.hero p {
    font-size: 18px;
}

.buttons .service-btn {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    background: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

.service-btn:hover {
    background: #555;
}

footer {
    text-align: center;
}

footer .footer-links, footer .social-media {
    margin-bottom: 5px;
}

footer .footer-links a, footer .social-media a {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
}
