body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    color: #333;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh; /* Adjust this value based on header and footer height */
    padding-top: 20vh;  /* Adjust to give space for the header */
    padding-bottom: 20vh;  /* Adjust to give space for the footer */
}

.login-card {
    /* background-color: #ffffff; */
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 30px 30px rgba(222, 10, 10, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.login-title {
    font-size: 1rem;
    color: #131313;
    margin-bottom: 30px;
    font-style: italic;
}

.login-input {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.login-input:focus {
    border-color: #00aaff;
}

.login-button {
    width: 100%;
    padding: 15px;
    background-color: #26a26f;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.login-button:hover {
    background-color: #26a6af;
}


