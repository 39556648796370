/* src/styles/hoverboxsections.css */
.featured-services {
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    padding: 2rem 0;
    text-align: center;
    overflow: visible;
    box-shadow: 0 0 10px rgba(18, 16, 9, 0.1); /* Added shadow values */
}

.featured-services .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.slick-slider {
    width: 100%;
    scrollbar-arrow-color: #a52020;
}

.featured-services .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(235, 6, 6, 0.1);
    transition: transform 0.3s;
    margin: 1rem 0.5rem;
}

.featured-services .service img {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 3/2;
}

.featured-services .service h3 {
    font-size: 1.5rem;
    margin: 15px 0 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ellipsis (...) to the end of overflowed text */
    white-space: nowrap; /* Prevents text from wrapping */
    width: 100%;
    box-sizing: border-box; /* Ensures padding/margins are considered within width */
}

.featured-services .service p {
    font-size: 1rem;
    color: #666;
    text-align: center;
}

/* Mobile styles */
@media (max-width: 768px) {
    .featured-services .service {
        width: 90%; /* Service boxes take up more space */
        margin: 1rem auto; /* Center the service box */
    }

    .featured-services .service img {
        width: 100%; /* Image fills the container */
        height: auto;
    }

    .featured-services .service h3 {
        font-size: 1.25rem;
        white-space: normal; /* Allow wrapping on smaller screens */
        overflow-wrap: break-word; /* Ensure long words break and wrap */

    }

    .featured-services .service p {
        font-size: 0.875rem; /* Smaller text on mobile */
    }
}
