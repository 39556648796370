/* Ensure the body and html take up the full height */
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    color: #333;
}

/* Container for the registration form */
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 80vh; /* Adjust this value based on header and footer height */
    padding-top: 20vh;  /* Adjust to give space for the header */
    padding-bottom: 20vh;  /* Adjust to give space for the footer */

}

/* Style for the registration card */
.register-card {
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

/* Title of the registration form */
.register-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 30px;
}

/* Input fields for the registration form */
.register-input {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.register-select {
    width: 100%;
    max-width: 100%; /* Ensure the dropdown doesn't exceed the screen width */
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color 0.3s ease;
    
    color: #333;
    appearance: none;
    overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

.register-select:focus {
    border-color: #00aaff;
    outline: none;
}

.register-select-wrapper {
    position: relative;
    width: 100%;
}

.register-select-wrapper::after {
    content: '▼';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #666;
    font-size: 12px;
}

/* Ensure dropdown options fit within the screen */
@media (max-width: 480px) {
    .register-select {
        font-size: 14px;
        padding: 10px;
    }

    /* Handle the dropdown overflow */
    .register-select-wrapper {
        max-width: 100%;
        overflow-x: auto;
    }

    .register-select option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; /* Adds '...' to cut-off text */
    }
}
.register-button {
    width: 100%;
    padding: 15px;
    background-color: #26a26f;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.register-button:hover {
    background-color: #26a6af;
}
