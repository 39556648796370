body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em;
  }
  
  section {
    background-color: white;
    margin: 1em 0;
    border-radius: 8px;
    padding: 2em;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  h2 {
    margin-top: 0;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-top: 1em;
  }
  
  input, select, button {
    margin-top: 0.5em;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.75em;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .dashboard-section {
    margin-top: 2em;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 0.75em;
    text-align: left;
  }
  
  .profile-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
  
  .profile-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: calc(33.333% - 2em);
  }
  
  .profile-card img {
    max-width: 100%;
    border-radius: 50%;
  }
  
  .profile-card h3 {
    margin: 0.5em 0;
  }
  
  .profile-card p {
    margin: 0;
  }