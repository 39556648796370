/* Popup Form Styling */
.popup {
    display: inline-flexbox; /* Hidden by default */
    position: fixed;
    z-index: 1001; /* Ensure it's lower than the header's z-index */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    
}

.popup-content {
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.close {
    color: #e41313;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: rgb(215, 19, 19);
    text-decoration: none;
    cursor: pointer;
}

form label {
    display: block;
    margin: 15px 0 5px;
    align-items: left;
}

form input[type="text"],
form input[type="number"],
form input[type="tel"],
form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin: 5px 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

form button {
    background-color: #f4b400;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
}

form button:hover {
    background-color: #d49e00;
}

/* Popup Button Styling */
#registerPanditjiBtn {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #d32f2f; /* Red background color */
    color: #fff; /* White text color */
    border: none;
    border-radius: 10px; /* Rounded corners */
    padding: 15px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 3D effect */
    font-size: 16px;
    cursor: pointer;
    z-index: 1001; /* Ensure it appears on top of other elements */
}

#registerPanditjiBtn:hover {
    background-color: #b71c1c; /* Darker red on hover */
}

#registerPanditjiBtn:active {
    background-color: #8b0000; /* Even darker red when clicked */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .popup-content {
        margin: 20% auto;
        padding: 15px;
        width: 90%;
    }

    form input[type="text"],
    form input[type="number"],
    form input[type="tel"],
    form input[type="file"],
    form button {
        font-size: 16px;
        padding: 8px;
    }

    .close {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .popup-content {
        margin: 25% 10px; /* Reduced margin and added padding for mobile */
        padding: 10px;
        width: calc(100% - 20px); /* Ensure it doesn't touch the screen edges */
    }

    form input[type="text"],
    form input[type="number"],
    form input[type="tel"],
    form input[type="file"],
    form button {
        font-size: 14px;
        padding: 6px;
    }

    .close {
        font-size: 20px;
    }
}

/* Show popup form when needed */
.popup.show {
    display: block;
}

@media (max-width: 768px) {
    .popup-content {
        margin: 20% auto 0; /* Adjusted to avoid header */
        padding: 15px;
        width: 90%;
    }
}

@media (max-width: 480px) {
    .popup-content {
        margin: 25% auto 0; /* Adjusted to avoid header */
        padding: 10px;
        width: calc(100% - 20px); /* Ensure it doesn't touch the screen edges */
    }
}

/* Ensure the popup shows when the state is true */
.popup.show {
    display: block;
}


.autocomplete-suggestions {
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    width: calc(100% - 20px); /* Adjust width as needed */
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Ensure it's positioned below the input */
    top: calc(100% + 5px); /* Position below the input with some margin */
    left: 0; /* Align with the left edge of the input */
}

.autocomplete-suggestion {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-suggestion:hover {
    background-color: #f0f0f0;
}

