/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

/* Featured Services */
.featured-servicess {
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    padding: 2rem 0;
    text-align: center;
    overflow: visible;
    box-shadow: #121009; /* If intended as a shadow, add proper values */
}

.featured-servicess h2 {
    font-size: 2rem !important;
    margin-bottom: 1rem;
    position: relative;
    
}

.featured-servicess h2::after {
    content: '';
    width: 100px;
    height: 3px;
    background: #f4b400;
    display: block;
    margin: 1rem auto;
}

.featured-servicess h3 {
    font-size: 80% !important;
    margin-bottom: 1rem;
    position: relative;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.service {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 30%;
    background: #44414100;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(235, 6, 6, 0.1);
    transition: transform 0.3s;
    margin: 1rem 0.5rem;
}

.service img {
    width: 100%;
    border-radius: 2px;
}

.service:hover {
    transform: translateY(-10px);
}

.featured-servicess .details p {
    margin: 0;
    text-align: left;
    white-space: pre-wrap;
}

.language-switcher {
    margin-top: -1cm;
    margin-bottom: 20px;
    text-align: center;
}

.language-switcher button {
    background-color: #e1e083;
    color: rgb(208, 88, 88);
    border: none;
    padding: 5px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1cm !important;
}

.language-switcher button:hover {
    background-color: #d1db8d;
}

.featured-servicess .services .service {
    position: relative;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.featured-servicess .services .service h3 {
    position: relative;
    font-size: medium !important;
    font-weight: bold !important;
    margin-bottom: 10px;
}

.featured-servicess .services .service p {
    margin-top: 0;
    text-align: left;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .service {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .service {
        width: 100%;
    }

    .featured-servicess h2 {
        font-size: 1.5rem !important;
    }

    .featured-servicess h3 {
        font-size: 70% !important;
    }
}

@media (max-width: 480px) {
    .featured-servicess h2 {
        font-size: 1.2rem !important;
    }

    .featured-servicess h3 {
        font-size: 60% !important;
    }

    .language-switcher button {
        padding: 5px 15px;
    }
}
