/* Navbar Styles */
.navbar {
  background: linear-gradient(to bottom, #f5c96c, #f9e4a5, #ffffff); /* Simplified gradient */
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.25rem;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"%3E%3Cpath stroke="%23000" stroke-width="2" d="M5 7h20M5 15h20M5 23h20"/%3E%3C/svg%3E');
}

/* Navbar Links */
.nav-link {
  color: #343a40; /* Dark gray for the links */
  font-size: 1rem;
}

.nav-link.active {
  font-weight: bold;
  color: #007bff; /* Blue color for the active link */
}

.navbar-nav.ml-auto {
  margin-left: auto;
}

.navbar-nav.ml-auto .nav-link {
  margin-right: 0; /* Add left margin to push the links to the right */
}

/* Dropdown Styles */
.dropdown-menu {
  background-color: #f9faf8; /* Light gray background */
  border: 1px solid #dee2e6; /* Border color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.dropdown-item {
  color: #343a40; /* Dark gray for the dropdown items */
}

.dropdown-item:hover {
  background-color: #707070
; /* Blue background on hover */
  color: #fff; /* White text on hover */
}

/* Nav About Section */
.navbar-nav-about {
  display: flex;
  align-items: center;
}

.navbar-nav-about .nav-link {
  margin-left: 15px;
}

/* Navigation Links Section */
.nav-links {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.username {
  margin-right: 10px;
  font-weight: bold;
}

/* Buttons */
.logout-button, .nav-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #26a26f;
  border: none;
  cursor: pointer;
}

.logout-button:hover, .nav-button:hover {
  background-color: #26a6af;
  color: #f5c96c; /* Change text color on hover */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.nav-button {
  color: #ffff;
  text-decoration: none;
}

.logout-button {
  color: #ffff;
  text-decoration: none;
}

/* Accessibility */
.nav-button:focus, .logout-button:focus, .nav-link:focus, .dropdown-item:focus {
  outline: 2px solid #007bff; /* Add a focus outline */
  outline-offset: 2px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-nav-about,
  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    width: 100%;
  }

  .nav-link, .nav-button, .logout-button {
    margin-left: 0;
    margin-top: 10px;
    text-align: left;
    padding: 12px; /* Increased padding for mobile */
    font-size: 18px; /* Larger font for better readability */
  }

  .username {
    margin-top: 10px;
    margin-right: 0;
    width: 100%;
    text-align: left;
    font-weight: bold;
  }

  /* Space between elements */
  .nav-links > * {
    margin-top: 10px;
  }

  /* Centering for mobile */
  .navbar-nav-about,
  .nav-links {
    justify-content: center; /* Center nav items on mobile */
  }

  .nav-link, .nav-button, .logout-button {
    text-align: center; /* Center text in buttons */
  }

  .username {
    text-align: center;
  }
}
/* Running Text Styles */
.running-text {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  color: #333;
  font-size: 14px; /* Adjusted font-size for better mobile view */
  line-height: 1.5; /* Adjust line-height if necessary */
  max-width: 100vw; /* Ensures the running text stays within the viewport */
}

.running-text-content {
  display: inline-block;
  padding-left: 100%; /* Start the text off-screen */
  animation: scroll-left 10s linear infinite; /* Default scroll speed */
}

.running-text a {
  color: #26a6af;
  text-decoration: none;
}

.running-text a:hover {
  text-decoration: underline;
}

@keyframes scroll-left {
  0% {
    transform: translateX(-100%); /* Start off-screen */
  }
  100% {
    transform: translateX(100%); /* Scroll all the way across the screen */
  }
}

/* Navbar Styles */
.navbar {
  position: relative;
  z-index: 10; /* Ensure navbar is on top of other content */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .running-text {
    font-size: 12px; /* Smaller font-size for mobile */
    max-width: 100vw; /* Prevents the running text from overflowing */
  }

  .running-text-content {
    padding-left: 100%;
    animation: scroll-left 20s linear infinite; /* Slower scroll speed on mobile */
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(-100%); /* Start off-screen */
    }
    100% {
      transform: translateX(100%); /* Scroll all the way across */
    }
  }
}
