/* Container */
.terms-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 25px;
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    animation: slideIn 1s ease-in-out;
  }
  
  /* Header */
  .terms-header {
    text-align: center;
    margin-bottom: 35px;
  }
  
  .terms-header h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 10px;
    position: relative;
  }
  
  .terms-header p {
    font-size: 1rem;
    color: #7f8c8d;
  }
  
  .terms-header h1::after {
    content: '';
    width: 70px;
    height: 4px;
    background-color: #e74c3c;
    display: block;
    margin: 10px auto;
    border-radius: 2px;
  }
  
  /* Content */
  .terms-content p,
  .terms-content ul {
    font-size: 1.1rem;
    color: #34495e;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .terms-content h2 {
    font-size: 1.8rem;
    color: #e74c3c;
    margin-top: 30px;
    position: relative;
    padding-bottom: 8px;
  }
  
  .terms-content h2::after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: #e74c3c;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 2px;
  }
  
  .terms-content ul {
    list-style-type: disc;
    padding-left: 40px;
  }
  
  .terms-content ul li {
    margin-bottom: 10px;
  }
  
  .terms-content a {
    color: #e74c3c;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .terms-content a:hover {
    color: #c0392b;
    text-decoration: underline;
  }
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .terms-container {
      padding: 20px;
      margin: 20px;
    }
  
    .terms-header h1 {
      font-size: 2rem;
    }
  
    .terms-content h2 {
      font-size: 1.5rem;
    }
  
    .terms-content p,
    .terms-content ul {
      font-size: 1rem;
    }
  }
  
  /* Animations */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  