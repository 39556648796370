/* Testimonials */
.testimonials {
    background: linear-gradient(to bottom, #f5c96c, #f7d28b, #f9e4a5, #fdf6e3, #ffffff);
    padding: 2rem 0;
    text-align: center;
}

.testimonials h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    position: relative;
}

.testimonials h2::after {
    content: '';
    width: 50px;
    height: 3px;
    display: block;
    margin: 1rem auto;
}

.testimonial-carousel {
    display: flex;
    justify-content: space-around;
}

.testimonial {
    width: 45%;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}