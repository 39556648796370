/* Container */
.privacy-policy-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Header */
  .privacy-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .privacy-header h1 {
    font-size: 2.5rem;
    color: #ff6f61;
    margin-bottom: 10px;
    position: relative;
  }
  
  .privacy-header p {
    font-size: 1rem;
    color: #555;
  }
  
  .privacy-header h1::after {
    content: '';
    width: 60px;
    height: 3px;
    background-color: #ff6f61;
    display: block;
    margin: 10px auto;
  }
  
  /* Content */
  .privacy-content p,
  .privacy-content ul {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .privacy-content h2 {
    font-size: 1.8rem;
    color: #ff6f61;
    margin-top: 30px;
    position: relative;
    padding-bottom: 10px;
  }
  
  .privacy-content h2::after {
    content: '';
    width: 40px;
    height: 3px;
    background-color: #ff6f61;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .privacy-content ul {
    list-style: none;
    padding-left: 0;
  }
  
  .privacy-content ul li {
    background: url('https://cdn-icons-png.flaticon.com/512/545/545682.png') left center no-repeat;
    background-size: 20px 20px;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  
  /* Links */
  .privacy-content a {
    color: #ff6f61;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .privacy-content a:hover {
    color: #ff3a3a;
    text-decoration: underline;
  }
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-header h1 {
      font-size: 2rem;
    }
  
    .privacy-content h2 {
      font-size: 1.5rem;
    }
  
    .privacy-content p, .privacy-content ul {
      font-size: 1rem;
    }
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  